export const environment = {
  production: true,
  credentials: {
    email: "",
    password: "",
  },
  baseUrl: "https://api.zonedeliveryservices.com",
  merchantUrl: "https://merchant.pharmacy.zonedeliveryservices.com",
  commonUrl: "/common/v2",
  ondemandUrl: "/ondemand/v2",
  socketUrl: "https://apiondemand.zonedeliveryservices.com/",
  errorHandlerArr: [],
  apiKey: "AIzaSyCsPriWr1muoozMnLsy5bFQRgLlZ88bZfU",
  superAdminAppId: 'bgfbdsa93upqr1bl36x7dlq7',
  pharmacyAppId: 'lfzyulw7hm4qitee9h13pr50',
  // walletEmail: 'adminpharmacy@zonedeliveryservices.com'
  walletEmail: 'superadmin@zonedeliveryservices.com'
};